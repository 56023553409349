import React, { useEffect, useRef } from 'react'
import {
  Box,
  Container,
  Text,
  Spinner,
  Heading,
  Button,
} from '@chakra-ui/react'
import Event from './event'
import { withErrorBoundary } from '../../../functionality/errorReporting'
import { BaseLink } from '../../../elements/link'
import { useLocation } from '@reach/router'
import { useEvents } from '../../../../api/events'
import { useGeo } from '../../../../api/queries'
import TextContainer from '../../../elements/textContainer'
import { eventData } from '../../../../api/eventData'
import { PackageSection } from '../../package/packageSection'
import { regions } from '../../../../api/constants'
import { StaticImage } from 'gatsby-plugin-image'

const ErrorScreen = () => (
  <Box
    minHeight={'300px'}
    bgColor={'#EFE7D6'}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
    textAlign={'center'}
  >
    There was an error loading upcoming events
  </Box>
)

const Partners = () => (
  <Box bgColor={'#e6dbc2'} pb={12} pt={10}>
    <Container>
      <Heading variant={'h1'} color={'tan.heading'} mb={8}>
        Partners
      </Heading>
      <TextContainer
        fontSize={'lg'}
        fontWeight={'bold'}
        sx={{ columns: '2 140px' }}
      >
        <ul style={{ paddingLeft: '20px' }}>
          {[
            'Phoenix & Dragon Bookstore',
            'Shelf Life Books',
            'Shining Lotus Bookstore',
            'Books and Books',
            'The Booksmith',
            'Black Bond Books',
            'Flying Books',
            'The Dock Shop',
            'Trident Booksellers',
            'Harvard Book Store',
            'Booktopia',
          ].map((text, index) => (
            <li key={index}>{text}</li>
          ))}
        </ul>
      </TextContainer>
    </Container>
  </Box>
)

const EventsSection = withErrorBoundary({ FallbackComponent: ErrorScreen })(
  ({ variant = 'home-page' }) => {
    const {
      geo: { country, region },
    } = useGeo()
    const {
      data: { current: events = [], past: pastEvents = [] } = {},
      isSuccess,
    } = useEvents()

    const scrolled = useRef(false)
    const location = useLocation()
    useEffect(() => {
      if (
        !scrolled.current &&
        isSuccess &&
        location.hash &&
        (location.hash === '#quiz' ||
          Object.values(eventData).some(
            (item) => location.hash === '#' + item.slug
          ))
      ) {
        const element = document.querySelector(location.hash)
        if (element) {
          element.scrollIntoView(true)
          scrolled.current = true
        }
      }
    }, [isSuccess, location.hash])

    let hasEvents = Array.isArray(events) && events.length > 0
    let hasPastEvents = Array.isArray(pastEvents) && pastEvents.length > 0
    let showPastEvents = variant !== 'home-page' && hasPastEvents
    const showEventsPageLink =
      variant === 'home-page' && events.length + pastEvents.length > 2

    let shownEvents = events

    // If all events are past, show them all at the top
    if (!hasEvents && hasPastEvents) {
      shownEvents = pastEvents
      hasEvents = true
      showPastEvents = false
      hasPastEvents = false
    }

    if (variant === 'home-page') {
      shownEvents = shownEvents.slice(0, 2)
    }

    const listEvents = (events, bgColor) =>
      events.map((event, index) => {
        return (
          <Event
            key={index}
            event={event}
            index={index}
            country={country}
            region={region}
            bgColor={bgColor}
          />
        )
      })

    return (
      <>
        <a id={'live-events'} />
        <Box
          position={'relative'}
          id={'tickets'}
          backgroundColor={'#f6f1e8'}
          pt='100'
          pb={8}
          zIndex={'0'}
        >
          <Box
            position={'absolute'}
            top={0}
            left={0}
            width={'100%'}
            zIndex={'-1'}
          >
            <Box display={['block', 'none']}>
              <StaticImage
                src={'../../../../images/events/backgroundMobile.jpg'}
                alt={'Background'}
                layout={'fullWidth'}
                quality={90}
              />
            </Box>
            <Box display={['none', 'block']}>
              <StaticImage
                src={'../../../../images/events/backgroundDesktop.jpg'}
                alt={'Background'}
                layout={'fullWidth'}
                quality={90}
              />
            </Box>
          </Box>

          <Container maxWidth='700px'>
            <Box mb={16}>
              <Heading
                mb={2}
                fontSize={['26px', '43px']}
                textTransform={'uppercase'}
                color={'tan.heading'}
                lineHeight={'1.2'}
              >
                Watch the Karma Virtual Tour Replays{' '}
                <span style={{ whiteSpace: 'nowrap' }}>With Sadhguru</span>
              </Heading>

              <TextContainer
                fontWeight={'normal'}
                ml={0.5}
                fontSize={['md', 'lg']}
                spacing={'.5em'}
                mb={10}
              >
                <p>
                  Sadhguru decodes the age-old misconceptions about the role of
                  karma in your life. As a companion to the book, this series
                  dives into the depths of karma and how you can use it as a
                  tool of empowerment.
                </p>
                <p>
                  Watch Sadhguru in conversation with notable authors &
                  personalities in medicine, sports, film and more.
                </p>
                <p>
                  These tour replays are exclusive and are not available in this
                  format on any other social media platforms.
                </p>
                {hasEvents &&
                  (region === regions.oceania ? (
                    <p>
                      If you would like to purchase tickets for more than one
                      event, Multiple event & Sponsor package options are
                      available.
                    </p>
                  ) : (
                    <p>
                      If you would like to purchase tickets for more than one
                      event, Patron & Sponsor package options are available.
                    </p>
                  ))}
              </TextContainer>

              {hasEvents && (
                <>
                  <Box textAlign={'center'} mt={4}>
                    <Button
                      as={'a'}
                      href={'#package'}
                      whiteSpace={'initial'}
                      lineHeight={'1.4'}
                      height={'auto'}
                      py={2}
                    >
                      {region !== regions.oceania
                        ? 'Patron and Sponsor Packages'
                        : 'Multiple Event Packages'}
                    </Button>
                  </Box>
                </>
              )}
            </Box>

            {!isSuccess && (
              <Box textAlign={'center'} minH={'1000px'}>
                <Spinner size={'lg'} color={'blue'} />
              </Box>
            )}

            {isSuccess && !hasEvents && (
              <Text textAlign={'center'} fontSize={'xl'}>
                There are currently no events scheduled.
              </Text>
            )}

            {hasEvents && listEvents(shownEvents, '#f6f1e8')}

            {showEventsPageLink && (
              <Box textAlign={'center'}>
                <Button variant={'solid'} as={BaseLink} href={'/events'}>
                  See all events
                </Button>
              </Box>
            )}
          </Container>
        </Box>

        {hasEvents && (
          <Box bgColor={'#f6f1e8'} id={'package'}>
            <Container>
              <Heading
                pt={12}
                mb={6}
                fontSize={['36px', '56px']}
                color={'tan.heading'}
              >
                Multiple Event Packages
              </Heading>
            </Container>
            <PackageSection showSingleEvent={false} />
          </Box>
        )}

        {showPastEvents && (
          <Box pt={12} pb={10} bgColor={'#e6dbc2'}>
            <Container maxWidth='700px'>
              <Heading mb={0} fontSize={['36px', '56px']} color={'tan.heading'}>
                Past Events
              </Heading>
              <Text fontSize={['md', 'xl']} lineHeight={1.4} mb={10}>
                Get access to the event recording for 24 hours from time of
                purchase
              </Text>
              {listEvents(pastEvents, '#e6dbc2')}
            </Container>
          </Box>
        )}
      </>
    )
  }
)

export default EventsSection
