import { useState } from 'react'
import { animate, useMotionValue } from 'framer-motion'
import { useInterval, useMeasure, useWindowSize } from 'react-use'
import useOnChange from '../../../hooks/useOnChange'

const clamp = (val, min, max) => Math.max(Math.min(val, max), min)

function useAutoplayController({
  autoplay,
  activeIndex,
  setActiveIndex,
  numSlides,
  rotationFrequency,
}) {
  const [canAutoplay, setCanAutoplay] = useState(true)
  const stopAutoplay = () => {
    setCanAutoplay(false)
  }

  useInterval(
    () => {
      setActiveIndex((activeIndex + 1) % numSlides, false)
    },
    autoplay && canAutoplay ? rotationFrequency * 1000 : null
  )

  return { stopAutoplay }
}

export default function useSliderController(
  numSlides,
  autoplay,
  rotationFrequency = 6
) {
  const [trackRef, trackDimensions] = useMeasure()
  const [childRef, childDimensions] = useMeasure()
  const slideWidth = childDimensions.width
  const windowDimensions = useWindowSize()
  const x = useMotionValue(0)
  const [activeIndex, _setActiveIndex] = useState(0)

  const showDots =
    trackDimensions.width === 0 ||
    trackDimensions.width > windowDimensions.width

  const dragConstraints = {
    left: -trackDimensions.right + windowDimensions.width,
    right: 0,
  }

  const setActiveIndex = (index) => {
    _setActiveIndex(index)

    const newX = clamp(
      -childDimensions.width * index,
      dragConstraints.left,
      dragConstraints.right
    )

    animate(x, newX, {
      type: 'spring',
      stiffness: 120,
      damping: 20,
    })
  }

  const onDragEnd = (event, info) => {
    // Remember: negative x offset is advancing the slider to next slides
    let xOffset = info.offset.x
    let xVelocity = info.velocity.x

    // If user is dragging up and down more than side to side,
    // don't change the current slide as this is likely a scroll
    if (Math.abs(xOffset) < Math.abs(info.offset.y)) {
      setActiveIndex(activeIndex) // This snaps the position to current slide
      return
    }

    let indexOffset = -Math.round(xOffset / slideWidth)

    if (indexOffset === 0) {
      if (Math.abs(xOffset) > slideWidth / 2) {
        indexOffset += xOffset > 0 ? -1 : 1
      } else if (Math.abs(xVelocity) > 100) {
        indexOffset += xVelocity > 0 ? -1 : 1
      }
    }

    const newIndex = clamp(activeIndex + indexOffset, 0, numSlides - 1)
    setActiveIndex(newIndex)
  }

  // Re-position the slides when window width changes
  useOnChange(() => {
    setActiveIndex(activeIndex)
  }, windowDimensions.width)

  const { stopAutoplay } = useAutoplayController({
    autoplay,
    activeIndex,
    setActiveIndex,
    numSlides,
    rotationFrequency,
  })

  return {
    activeIndex,
    childRef,
    showDots,
    onDotClick: (index) => {
      setActiveIndex(index)
      stopAutoplay()
    },
    trackProps: {
      ref: trackRef,
      drag: 'x',
      dragDirectionLock: true,
      dragConstraints,
      style: { x },
      onDragStart: stopAutoplay,
      onDragEnd,
    },
  }
}
