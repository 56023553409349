import React, { forwardRef } from 'react'
import MotionBox from '../../elements/motionBox'
import useSliderController from './controller'
import { Box, HStack } from '@chakra-ui/react'
import * as PropTypes from 'prop-types'

const Track = forwardRef(
  ({ padding, justifyContent = 'flex-start', ...otherProps }, ref) => (
    <MotionBox
      display={'flex'}
      flexWrap={'nowrap'}
      justifyContent={justifyContent}
      minWidth={'min-content'}
      padding={padding}
      sx={{
        cursor: 'grab',
        '&:active': { cursor: 'grabbing' },
        '& picture, & img': {
          userDrag: 'none',
          WebkitUserDrag: 'none',
          pointerEvents: 'none',
        },
      }}
      ref={ref}
      {...otherProps}
    />
  )
)

Track.displayName = 'Track'

function Dot({ index, activeIndex, onClick, color = 'white' }) {
  return (
    <Box
      width={'16px'}
      height={'16px'}
      borderRadius={'100%'}
      border={'solid 2px'}
      borderColor={color}
      bgColor={index === activeIndex ? color : '#FFFFFF00'}
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  )
}

Dot.propTypes = {
  index: PropTypes.number,
  activeIndex: PropTypes.number,
  onClick: PropTypes.func,
}

export function Slider({
  children,
  justifyContent,
  dotColor = 'white',
  autoplay,
  autoplayFrequency,
}) {
  const {
    childRef,
    trackProps,
    activeIndex,
    showDots,
    onDotClick,
  } = useSliderController(
    React.Children.count(children),
    autoplay,
    autoplayFrequency
  )
  return (
    <Box overflow={'hidden'}>
      <Track justifyContent={justifyContent} {...trackProps}>
        {React.Children.map(children, (child, index) =>
          index === 0 ? React.cloneElement(child, { ref: childRef }) : child
        )}
      </Track>
      <HStack
        spacing={4}
        justifyContent={'center'}
        mt={[4, 6]}
        style={{ opacity: showDots ? 1 : 0, transition: '.3s all' }}
      >
        {React.Children.map(children, (child, index) => (
          <Dot
            color={dotColor}
            index={index}
            activeIndex={activeIndex}
            onClick={() => onDotClick(index)}
          />
        ))}
      </HStack>
    </Box>
  )
}
