import { Box, Stack, Heading } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Slider } from '../../functionality/slider/slider'

const Endorsement = forwardRef(({ image, text, name }, ref) => (
  // We're using resizeObserver API, which reports the contentRect
  // (doesn't take into account padding or borders) so we wrap the component
  // and put the ref on the wrapper
  <div ref={ref}>
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={8}
      alignItems={'center'}
      pr={12}
    >
      <Box width={{ base: '80vw', sm: '400px', md: '400px', lg: '500px' }}>
        {image}
      </Box>
      <Box
        width={{ base: '80vw', sm: '400px', md: '260px', lg: '360px' }}
        pl={{ base: 6, md: 0 }}
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        <Heading
          as={'p'}
          textStyle={'heading'}
          fontSize={'inherit'}
          color={'white'}
          mb={4}
        >
          {text}
        </Heading>
        <Heading
          as={'p'}
          textStyle={'heading'}
          color={'gold'}
          fontSize={'inherit'}
        >
          - {name}
        </Heading>
      </Box>
    </Stack>
  </div>
))

Endorsement.displayName = 'Endorsement'

export default function EndorsementsSection() {
  return (
    <Box py={[8, 16]} bgColor={'#030040'} sx={{ overflow: 'hidden' }}>
      <Slider autoplay={true} autoplayFrequency={8}>
        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/willSmith.png'}
              alt={'Will Smith with Sadhguru'}
              draggable={false}
            />
          }
          text={
            <>
              “With Karma, Sadhguru explores both the limitations and
              possibilities of human existence. It is full of valuable insights
              to guide you to live a life of fulfillment and well-being.”
            </>
          }
          name={'Will Smith'}
        />
        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/rosannaArquette.png'}
              alt={'Rosanna Arquette with Sadhguru'}
            />
          }
          text={
            <>
              “The tools Sadhguru provides in Karma bring me to a place of peace
              within myself. Thank you for your wisdom and transformational
              guidance.”
            </>
          }
          name={'Rosanna Arquette'}
        />
        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/tomBrady.png'}
              alt={'Tom Brady with Sadhguru'}
            />
          }
          text={
            <>
              “Forget what you think you know about karma—Sadhguru shows us it’s
              not a punishment for bad behavior, but a vehicle for
              transformation and empowerment. This book will put you back in
              charge of your own life.”
            </>
          }
          name={'Tom Brady'}
        />
        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/princessNoor.png'}
              alt={'HRH Princess Noor Bint Asem of Jordan with Sadhguru'}
            />
          }
          text={
            <>
              "The words in this book are the key to unlocking your truth, to
              see with no eyes, to hear the truth that lies in silence, and to
              connect with your inner wisdom."
            </>
          }
          name={'HRH Princess Noor Bint Asem of Jordan'}
        />

        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/tonyRobbins.png'}
              alt={'Tony Robbins with Sadhguru'}
            />
          }
          text={
            <>
              “Karma is a thoughtful and life-affirming reminder of our vast
              capacity to effect positive change in our own life and in the
              lives of others. A must-read for anyone feeling stuck and
              hopeless.”
            </>
          }
          name={'Tony Robbins'}
        />

        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/deepakChopra.png'}
              alt={'Deepak Chopra with Sadhguru'}
            />
          }
          text={
            <>
              “At last, a book about karma that can be trusted. I have never
              found a book that explains—and solves—the mystery of karma with
              the simplicity, clarity, and hopefulness of this invaluable book.”
            </>
          }
          name={'Deepak Chopra'}
        />

        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/stevenLaureys.png'}
              alt={'Steven Laureys with Sadhguru'}
            />
          }
          text={
            <>
              “Sadhguru here offers an easy read on a difficult subject: karma,
              or the volition to perform action. A truly captivating view from a
              renowned yogi and mystic on free will and the destiny of the human
              mind.”
            </>
          }
          name={'Prof. Dr. Steven Laureys'}
        />

        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/terryTamminen.png'}
              alt={'Terry Tamminen with Sadhguru'}
            />
          }
          text={
            <>
              “Sadhguru brilliantly demystifies the concept of karma and how we
              can create a more sustainable, just, and spiritually enlightened
              world. If you want to be the change you want to see in the world,
              read Karma and begin the journey.”
            </>
          }
          name={'Terry Tamminen'}
        />

        <Endorsement
          image={
            <StaticImage
              src={'../../../images/index/endorsements/jayNaidoo.png'}
              alt={'Jay Naidoo with Sadhguru'}
            />
          }
          text={
            <>
              “This five-letter word that has baffled humans for thousands of
              years is finally explained in 272 pages. It’s a compass for
              navigating life. Thank you, Sadhguru.”
            </>
          }
          name={'Jay Naidoo'}
        />
      </Slider>
    </Box>
  )
}
