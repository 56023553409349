import { useEffect, useRef } from 'react'

export default function useOnChange(fn, value) {
  const prevValue = useRef(value)

  useEffect(() => {
    if (value !== prevValue.current) {
      fn()
      prevValue.current = value
    }
  }, [value, fn])
}
