import { useGeo } from '../../../api/queries'
import {
  PackageSubheading,
  PatronPackageItems,
  patronPackageUrl,
  Separator,
  SpacedHeading,
  SponsorPackageItems,
  sponsorPackageUrl,
} from './elements'
import { Box, Button, Container, Skeleton, Stack, Text } from '@chakra-ui/react'
import { BaseLink } from '../../elements/link'
import { liveEventsUrl } from '../../functionality/navigation/urls'
import React from 'react'
import TextContainer from '../../elements/textContainer'
import { regions } from '../../../api/constants'
import { ButtonWithSubtext } from '../../elements/buttonWithSubtext'
import { SponsorPackageAuText } from './elementsAu'

const Package = ({ name, link, text, topText, subText }) => (
  <Box pb={8}>
    <Separator />
    <Container pb={8} color={'blue'}>
      {topText && <TextContainer mb={8}>{topText}</TextContainer>}
      <SpacedHeading mb={0}>{name}</SpacedHeading>
      <Text fontStyle={'italic'} mb={4}>
        {subText}
      </Text>
      <Button as={BaseLink} href={link}>
        Purchase Package
      </Button>
      <TextContainer mt={6} spacing={null}>
        {text}
      </TextContainer>
    </Container>
  </Box>
)

export const PackageSection = ({ showSingleEvent = true }) => {
  const {
    geo: { country, region },
    isSuccess,
  } = useGeo()
  const isCanada = country === 'CA'
  const patronFee = isCanada ? 'CA-$330' : '$250'
  const sponsorFee = isCanada ? 'CA-$235' : '$185'
  return (
    <>
      {showSingleEvent && (
        <>
          <Separator />
          <Container mb={12}>
            <Skeleton isLoaded={isSuccess}>
              <SpacedHeading mb={2}>Single Event</SpacedHeading>

              <Text style={{ fontWeight: 'bold' }} mb={4}>
                If you wish to attend a select single event, you can purchase
                the tickets via the bookstores directly. Price:{' '}
                {isCanada ? 'CAD $48' : '$35'} + shipping cost for a copy of the
                book.
              </Text>
              <Button
                variant={'outline'}
                colorScheme={'blue'}
                as={BaseLink}
                href={liveEventsUrl}
              >
                See Upcoming Events
              </Button>
            </Skeleton>
          </Container>
        </>
      )}
      {region !== regions.oceania && (
        <>
          <Package
            name={'Patron Package'}
            subText={'(Available to view for 30 days from date of purchase)'}
            link={patronPackageUrl}
            topText={
              <>
                <PackageSubheading>Patronage Request:</PackageSubheading>
                <p>
                  These packages include a copy of the Karma book personally
                  signed by Sadhguru.
                  <br />
                  <em>Karma</em> is an impactful and transformative tool that
                  can empower people during these challenging times. By opting
                  for the patron or sponsor ticket, you are supporting Isha’s
                  endeavor to bring this powerful guide to all seekers, far and
                  wide.
                </p>
              </>
            }
            text={
              <>
                <PatronPackageItems />

                <PackageSubheading>Fee:</PackageSubheading>
                <Skeleton isLoaded={isSuccess}>
                  {isCanada ? (
                    <p>{patronFee}</p>
                  ) : (
                    <p>
                      {patronFee} or higher
                      <br />
                      Any amount above {patronFee} is tax deductible.
                    </p>
                  )}
                </Skeleton>
              </>
            }
          />

          <Package
            name={'Sponsor Package'}
            link={sponsorPackageUrl}
            text={
              <>
                <SponsorPackageItems />

                <Skeleton isLoaded={isSuccess}>
                  <PackageSubheading>
                    Fee:{' '}
                    <span style={{ fontWeight: 'normal' }}>{sponsorFee}</span>
                  </PackageSubheading>
                </Skeleton>
              </>
            }
          />
        </>
      )}

      {region === regions.oceania && (
        <>
          <Box pb={8}>
            <Separator />
            <Container pb={8} color={'blue'}>
              <SpacedHeading mb={0}>Multiple Event Package</SpacedHeading>
              <Stack direction={['column', 'row']} mt={6}>
                <ButtonWithSubtext
                  text={'Buy Tickets - $55'}
                  subText={'Includes a Paperback Book'}
                  href={'/ticketing/multiple-event-package'}
                />
                <ButtonWithSubtext
                  text={'Event-Only Tickets - $40'}
                  subText={'Book Not Included'}
                  href={'/ticketing/multiple-event-only-package'}
                />
              </Stack>
              <TextContainer>
                <PackageSubheading>Package includes:</PackageSubheading>
                <ul>
                  <li>Access to 3 events</li>
                </ul>
              </TextContainer>
            </Container>
          </Box>

          <Package
            name={'Sponsor Package'}
            link={'/ticketing/sponsor-package-au'}
            text={<SponsorPackageAuText />}
            subText={
              "By opting for the sponsor package you are supporting Isha Australia's endeavor to bring this powerful guide to all seekers, far and wide"
            }
          />
        </>
      )}
    </>
  )
}
